/* eslint-disable */
import { lazy } from 'react';
import { USER_ROLE } from 'constants.js';
import { DEFAULT_PATHS } from 'config.js';


const dashboard = lazy(() => import('views/dashboard/Dashboard'));
const products = {
  list: lazy(() => import('views/products/list/ProductsList')),
  detail: lazy(() => import('views/products/detail/ProductsDetail')),
};
const orders = {
  list: lazy(() => import('views/orders/list/OrdersList')),
  detail: lazy(() => import('views/orders/detail/OrdersDetail')),
};
const customers = {
  list: lazy(() => import('views/customers/list/CustomersList')),
  detail: lazy(() => import('views/customers/detail/CustomersDetail')),
};

const storefront = {
  home: lazy(() => import('views/storefront/home/Home')),
  filters: lazy(() => import('views/storefront/filters/Filters')),
  categories: lazy(() => import('views/storefront/categories/Categories')),
  detail: lazy(() => import('views/storefront/detail/Detail')),
  cart: lazy(() => import('views/storefront/cart/Cart')),
  checkout: lazy(() => import('views/storefront/checkout/Checkout')),
  invoice: lazy(() => import('views/storefront/invoice/Invoice')),
};
const shipping = lazy(() => import('views/shipping/Shipping'));
const discount = lazy(() => import('views/discount/Discount'));
const requisition = lazy(() => import('views/requisition/Requisition'));
const gigexecution = lazy(() => import('views/gigexecution/Gigexecution'));
const history = lazy(() => import('views/history/History'));
const companies = lazy(() => import('views/companies/Companies'));
const giggers = lazy(() => import('views/giggers/Giggers'));
const gigconfig = lazy(() => import('views/gigconfig/Gigconfig'));
const invoice = lazy(() => import('views/invoice/Invoice'));

const settings = {
  home: lazy(() => import('views/settings/home/Home')),
  general: lazy(() => import('views/settings/general/General')),
};

const services = {
  users: lazy(() => import('views/services/ServicesUsers')),
};

const account = {
  security: lazy(() => import('views/account/AccountSecurity')),
};

const support = {
  docs: lazy(() => import('views/support/SupportDocs')),
  knowledgeBase: lazy(() => import('views/support/SupportKnowledgeBase')),
};

const appRoot = DEFAULT_PATHS.APP.endsWith('/') ? DEFAULT_PATHS.APP.slice(1, DEFAULT_PATHS.APP.length) : DEFAULT_PATHS.APP;


const routesAndMenuItems = {
  mainMenuItems: [
    {
      path: DEFAULT_PATHS.APP,
      exact: true,
      redirect: true,
      to: `${appRoot}/login`,
    },
    {
      path: `${appRoot}/dashboard`,
      component: dashboard,
      label: 'menu.dashboard',
      icon: 'shop',
    },
    {
      path: `${appRoot}/gig-execution`,
      component: gigexecution,
      label: 'Gig Execution',
      icon: 'customgigexecution',
      strokeWidth: 0.5
    },
    {
      path: `${appRoot}/companies`,
      component: companies,
      label: 'Companies',
      icon: 'customcompanies'
      // hideInRoute: true,
    },
    {
      path: `${appRoot}/giggers`,
      component: giggers,
      label: 'Giggers',
      icon: 'customgiggers',
      // isAdmin: true,
    },
    {
      path: `${appRoot}/gig-config`,
      component: gigconfig,
      label: 'Gig Config',
      icon: 'customgigconfig'
    },
    {
      path: `${appRoot}/logout`,
      exact: true,
      label: 'Logout',
      icon: 'logout',
      isBottom: 1
    },
  ],
  sidebarItems: [],
};
export default routesAndMenuItems;
