import { createSlice } from '@reduxjs/toolkit';
import { DEFAULT_USER, IS_DEMO } from 'config.js';

const initialState = {
  isLogin: IS_DEMO,
  currentUser: IS_DEMO ? DEFAULT_USER : {},
  functionality: {},
  dataAccess: {}
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setCurrentUser(state, action) {
      state.currentUser = action.payload;
      state.isLogin = true;
    },
    setCurrentUserFunctionality(state, action) {
      state.functionality = action.payload;
    },
    setCurrentUserDataAccess(state, action) {
      state.dataAccess = action.payload;
    },
    removeCurrentUser(state) {
      state.currentUser = {};
      state.isLogin = false;
      state.functionality = {};
      state.dataAccess = {};
    },
    refreshCurrentUser(state, action) {
      state.currentUser = action.payload.user;
      state.isLogin = action.payload.isLogin;
      state.functionality = action.payload.functionality;
    }
  },
});

export const { setCurrentUser, setCurrentUserFunctionality, setCurrentUserDataAccess, removeCurrentUser, refreshCurrentUser } = authSlice.actions;
const authReducer = authSlice.reducer;

export default authReducer;
